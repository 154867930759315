import { useState } from 'react'

import { Box, CloseButton, Divider, Input, Tooltip } from '@mantine/core'
import dayjs from 'dayjs'

import { useMutation } from '@redwoodjs/web'

import DNButton from 'src/components/Buttons/Button/DNButton'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { useApplicantDrawerContext } from 'src/context/ApplicantDrawerContext'
import { useCallDrawerContext } from 'src/context/CallDrawerContext'
import { CANCEL_BOOKING } from 'src/graphql/bookings.graphql'
import IconRightArrow from 'src/icons/IconRightArrow'
import { buildFullName } from 'src/lib/formatters'
import { cn } from 'src/utils'

const EventDetails = ({ booking, onClose, refetch }) => {
  const { handleOpen: openCallDrawer } = useCallDrawerContext()
  const [cancellationReason, setCancellationReason] = useState('')
  const [showCancelBox, setShowCancelBox] = useState(false)
  const { handleOpen } = useApplicantDrawerContext()
  const title =
    booking.title ||
    `${buildFullName(booking.applicant)} <> ${buildFullName(booking.employee)}`

  const [cancelBooking, { loading }] = useMutation(CANCEL_BOOKING, {
    onCompleted: () => {
      toast('Booking cancelled successfully', 'success')
      refetch()
      onClose()
    },
    onError: () => {
      toast('Booking cancellation failed', 'error')
    },
  })

  return (
    <div className="flex flex-1 flex-col gap-4 whitespace-pre-wrap p-3">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-start justify-between gap-2 text-sm font-medium text-doubleNickel-gray-900">
          <Tooltip label={title}>
            <div
              className={cn(
                'mt-1',
                booking.status === 'CANCELLED' && 'line-through'
              )}
            >
              {title}
            </div>
          </Tooltip>

          <CloseButton onClick={onClose} />
        </div>
        <div className="flex w-full flex-row items-center justify-start gap-2 overflow-auto">
          {booking?.activityLogId && (
            <DNButton
              variant="secondary"
              onClick={() => {
                onClose()
                openCallDrawer(booking.activityLogId)
              }}
            >
              Go to call
              <IconRightArrow className="h-3 w-3 fill-none stroke-doubleNickel-gray-600" />
            </DNButton>
          )}
          {booking.status === 'ACCEPTED' && (
            <DNButton
              variant="secondary"
              onClick={() => setShowCancelBox(true)}
            >
              Cancel booking
            </DNButton>
          )}
        </div>
      </div>

      <Divider className="bg-doubleNickel-gray-200" />
      <div className="flex flex-row items-start justify-start gap-2 text-sm text-doubleNickel-gray-600">
        <div className="flex flex-1 flex-col items-start justify-center">
          <div className="text-xs font-medium text-doubleNickel-gray-900">
            Applicant
          </div>
          <Box
            className={cn(
              booking.applicant.applicantId &&
                'hover:cursor-pointer hover:text-doubleNickel-brand-500 hover:underline'
            )}
            onClick={() => {
              if (booking.applicant.applicantId) {
                onClose()
                handleOpen(booking.applicant.applicantId)
              }
            }}
          >
            {buildFullName(booking.applicant)}
          </Box>
        </div>
        <div className="flex flex-1 flex-col items-start justify-center">
          <div className="text-xs font-medium text-doubleNickel-gray-900">
            Recruiter
          </div>
          <div>{buildFullName(booking.employee)}</div>
        </div>
      </div>

      <div className="flex flex-row items-start justify-start gap-2 text-sm text-doubleNickel-gray-600">
        <div className="flex flex-1 flex-col items-start justify-center">
          <div className="text-xs font-medium text-doubleNickel-gray-900">
            Time
          </div>
          <div>
            {dayjs(booking.bookingStartTime).format('hh:mm A')} -{' '}
            {dayjs(booking.bookingEndTime).format('hh:mm A')}
          </div>
        </div>
        <div className="flex flex-1 flex-col items-start justify-center">
          <div className="text-xs font-medium text-doubleNickel-gray-900">
            Duration
          </div>
          <Box>
            {booking?.bookingDurationInMinutes ||
              dayjs(booking.bookingEndTime).diff(
                booking.bookingStartTime,
                'minute'
              )}{' '}
            min
          </Box>
        </div>
      </div>

      <Divider className="bg-doubleNickel-gray-200" />

      {showCancelBox ? (
        <div className="flex flex-col items-center justify-end gap-2">
          <div className="w-full text-left text-xs font-medium text-doubleNickel-gray-900">
            Cancel the booking:
          </div>
          <Input
            placeholder="Cancellation reason"
            className="w-full"
            value={cancellationReason}
            onChange={(e) => setCancellationReason(e.target.value)}
          />
          <div className="mt-2 flex w-full flex-row items-center justify-end gap-3">
            <DNButton
              onClick={() => setShowCancelBox(false)}
              variant="transparent"
            >
              Nevermind
            </DNButton>
            <DNButton
              loading={loading}
              className="min-w-[150px]"
              variant="error"
              onClick={() => {
                if (loading) return
                cancelBooking({
                  variables: {
                    bookingId: booking.bookingId,
                    cancellationReason: cancellationReason,
                  },
                })
              }}
            >
              Confirm cancellation
            </DNButton>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col items-start justify-start gap-1 text-sm text-doubleNickel-gray-600">
            <div className="text-xs font-medium text-doubleNickel-gray-900">
              Description:
            </div>
            <div className="flex flex-col">
              {booking?.description
                ? booking?.description
                    .split('\\n')
                    .map((str, index) => <div key={index}>{str}</div>)
                : 'No description'}
            </div>
          </div>
          <Divider className="bg-doubleNickel-gray-200" />
        </>
      )}
    </div>
  )
}

export default EventDetails
