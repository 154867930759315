import { Divider } from '@mantine/core'

import { useAuth } from 'src/auth'
import Tag from 'src/components/DataDisplay/Tag/Tag'
import IconCheckmark from 'src/icons/IconCheckmark'

const CalendarFiltersRow = ({ setSelectedRecruiters, selectedRecruiters }) => {
  const { currentUser } = useAuth()
  const fullName = `${currentUser?.firstName} ${currentUser?.lastName}`

  return (
    <div className="flex h-11 w-full flex-row items-center gap-4 overflow-hidden">
      <div className="flex flex-row items-center gap-2 overflow-auto">
        {selectedRecruiters.map((recruiter) => (
          <Tag
            key={recruiter.value}
            onClose={() => {
              setSelectedRecruiters(
                selectedRecruiters.filter(
                  (selectedRecruiter) =>
                    selectedRecruiter.value !== recruiter.value
                )
              )
            }}
          >
            {' '}
            {recruiter.label}
          </Tag>
        ))}
      </div>

      <Divider orientation="vertical" className="ml-auto" />
      <div className="text-left text-xs font-medium text-doubleNickel-gray-700">
        Suggested:
      </div>

      <Tag
        clickable={true}
        onClick={() => {
          if (
            selectedRecruiters.find(
              (recruiter) => recruiter.value === currentUser.employeeId
            )
          ) {
            // Remove the recruiter
            setSelectedRecruiters(
              selectedRecruiters.filter(
                (recruiter) => recruiter.value !== currentUser.employeeId
              )
            )
          } else {
            // Add the recruiter
            setSelectedRecruiters([
              ...selectedRecruiters,
              {
                label: fullName,
                value: currentUser.employeeId,
              },
            ])
          }
        }}
        showCloseButton={false}
      >
        {selectedRecruiters.find(
          (recruiter) => recruiter.value === currentUser.employeeId
        ) && (
          <IconCheckmark className="h-3 w-3 fill-none stroke-doubleNickel-brand-400" />
        )}
        {fullName}
      </Tag>
    </div>
  )
}

export default CalendarFiltersRow
