import { useContext, useRef } from 'react'

import { ActionIcon, Box } from '@mantine/core'
import dayjs from 'dayjs'

import Button from 'src/components/Buttons/Button/Button'
import CheckboxMenu from 'src/components/Overlays/CheckboxMenu/CheckboxMenu'
import { RecruitersContext } from 'src/context/RecruitersContext'
import IconChevronLeft from 'src/icons/IconChevronLeft'
import IconFilter from 'src/icons/IconFilter'

import { addWeek, calculateWeekDateRange, subtractWeek } from '../utils'

const CalendarHeader = ({
  calendarStartDate,
  calendarEndDate,
  setCalendarStartDate,
  setCalendarEndDate,
  selectedRecruiters,
  setSelectedRecruiters,
}) => {
  const buttonRef = useRef(null)
  const recruiters = useContext(RecruitersContext)

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="grid w-full grid-cols-3 items-baseline justify-between gap-4 text-lg font-semibold text-doubleNickel-gray-900">
        <div>{dayjs(calendarStartDate).format('MMMM YYYY')}</div>
        <div className="flex flex-row items-center justify-center gap-4 text-sm font-medium text-doubleNickel-gray-600">
          <ActionIcon
            variant="subtle"
            color="gray"
            onClick={() => {
              const { startDate, endDate } = subtractWeek({
                start: calendarStartDate,
                end: calendarEndDate,
              })
              setCalendarStartDate(startDate)
              setCalendarEndDate(endDate)
            }}
          >
            <IconChevronLeft className="h-4 fill-none stroke-doubleNickel-gray-700" />
          </ActionIcon>
          <Box
            className="cursor-pointer rounded p-2 px-4 hover:bg-doubleNickel-gray-100"
            onClick={() => {
              const { startDate, endDate } = calculateWeekDateRange()
              setCalendarStartDate(startDate)
              setCalendarEndDate(endDate)
            }}
          >
            Today
          </Box>
          <ActionIcon
            variant="subtle"
            color="gray"
            onClick={() => {
              const { startDate, endDate } = addWeek({
                start: calendarStartDate,
                end: calendarEndDate,
              })
              setCalendarStartDate(startDate)
              setCalendarEndDate(endDate)
            }}
          >
            <IconChevronLeft className="h-4 rotate-180 fill-none stroke-doubleNickel-gray-700" />
          </ActionIcon>
        </div>
        <div className="ml-auto">
          <CheckboxMenu
            items={recruiters}
            selectedItems={selectedRecruiters}
            setSelectedItems={setSelectedRecruiters}
            fallbackText={'No recruiters found'}
          >
            <Button
              ref={buttonRef}
              variant="outline"
              text={`Recruiter: ${
                selectedRecruiters.length === 0
                  ? 'All'
                  : selectedRecruiters.length
              }`}
              lefticon={<IconFilter />}
            />
          </CheckboxMenu>
        </div>
      </div>
    </div>
  )
}

export default CalendarHeader
