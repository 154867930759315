import dayjs from 'dayjs'

export const calculateWeekDateRange = () => {
  const currentDate = new Date()
  const currentDayOfWeek = currentDate.getDay()
  const startOfWeek = dayjs(currentDate).subtract(currentDayOfWeek, 'day')
  const startDate = new Date(startOfWeek.toDate())
  startDate.setHours(0, 0, 0, 0)
  const endOfWeek = dayjs(startOfWeek).add(6, 'day')
  const endDate = new Date(endOfWeek.toDate())
  endDate.setHours(23, 59, 59, 999)
  return { startDate, endDate }
}

export const subtractWeek = ({ start, end }) => {
  const newStartDate = dayjs(start).subtract(7, 'day')
  const newEndDate = dayjs(end).subtract(7, 'day')
  const startDate = new Date(newStartDate.toDate())
  startDate.setHours(0, 0, 0, 0)
  const endDate = new Date(newEndDate.toDate())
  endDate.setHours(23, 59, 59, 999)
  return { startDate, endDate }
}

export const addWeek = ({ start, end }) => {
  const newStartDate = dayjs(start).add(7, 'day')
  const newEndDate = dayjs(end).add(7, 'day')
  const startDate = new Date(newStartDate.toDate())
  startDate.setHours(0, 0, 0, 0)
  const endDate = new Date(newEndDate.toDate())
  endDate.setHours(23, 59, 59, 999)
  return { startDate, endDate }
}
