import dayjs from 'dayjs'

import { buildFullName } from 'src/lib/formatters'
import { cn } from 'src/utils'

const EventCard = ({ booking }) => {
  const title =
    booking.title ||
    `${buildFullName(booking.applicant)} <> ${buildFullName(booking.employee)}`

  const duration =
    booking?.bookingDurationInMinutes ||
    dayjs(booking.bookingEndTime).diff(booking.bookingStartTime, 'minute')

  return (
    <div
      className={cn(
        'flex w-full cursor-pointer flex-col gap-1 rounded bg-doubleNickel-gray-100 p-2 text-sm hover:bg-doubleNickel-gray-200',
        booking.status === 'CANCELLED' && 'line-through',
        duration > 30 ? 'min-h-32' : 'min-h-16'
      )}
    >
      <div className="text-xs text-doubleNickel-gray-800">{title}</div>
      <div className="text-xs text-doubleNickel-gray-500">
        {dayjs(booking.bookingStartTime).format('hh:mm A')} -{' '}
        {dayjs(booking.bookingEndTime).format('hh:mm A')}
      </div>
    </div>
  )
}

export default EventCard
