import { useEffect, useState } from 'react'

import { Menu, MenuDropdown, MenuTarget } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import dayjs from 'dayjs'

import { useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { GET_BOOKINGS } from 'src/graphql/bookings.graphql'
import IconAdd from 'src/icons/IconAdd'
import { cn } from 'src/utils'

import CalendarFiltersRow from './components/CalendarFiltersRow'
import CalendarHeader from './components/CalendarHeader'
import EventCard from './components/EventCard'
import EventDetails from './components/EventDetails'

const DayColumn = ({ children }) => {
  const { hovered, ref } = useHover()

  return (
    <div
      className="flex h-full flex-col items-center justify-start gap-2 overflow-auto rounded-lg border border-doubleNickel-gray-200 p-1"
      ref={ref}
    >
      {children}

      {hovered && (
        <div className="hidden w-full cursor-pointer items-center justify-center bg-doubleNickel-gray-50 p-2 hover:bg-doubleNickel-gray-200">
          <IconAdd className="h-3 fill-none stroke-doubleNickel-gray-500" />
        </div>
      )}
    </div>
  )
}

const CalendarEvent = ({ booking, refetch }) => {
  const [opened, setOpened] = useState(false)

  return (
    <Menu
      position="right-start"
      width={400}
      opened={opened}
      onChange={setOpened}
    >
      <MenuTarget>
        <div key={booking.bookingId} className="w-full">
          <EventCard booking={booking} />
        </div>
      </MenuTarget>
      <MenuDropdown className="shadow-lg">
        <div className="max-h-[65vh] overflow-auto">
          <EventDetails
            booking={booking}
            onClose={() => setOpened(false)}
            refetch={refetch}
          />
        </div>
      </MenuDropdown>
    </Menu>
  )
}

const CalendarPage = () => {
  const params = useParams()
  const [selectedRecruiters, setSelectedRecruiters] = useState([])
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()
  const firstDayOfWeek = currentDate.getDate() - currentDate.getDay()
  const startDate = params?.startDate
    ? new Date(params.startDate)
    : new Date(currentYear, currentMonth, firstDayOfWeek)
  startDate.setHours(0, 0, 0, 0)
  const endDate = params?.endDate
    ? new Date(params.endDate)
    : new Date(currentYear, currentMonth, firstDayOfWeek + 6)
  endDate.setHours(23, 59, 59, 999)

  const [calendarStartDate, setCalendarStartDate] = useState(startDate)

  const [calendarEndDate, setCalendarEndDate] = useState(endDate)

  const [days, setDays] = useState([])

  useEffect(() => {
    setDays(
      Array.from(
        { length: 7 },
        (_, i) =>
          new Date(
            calendarStartDate.getFullYear(),
            calendarStartDate.getMonth(),
            calendarStartDate.getDate() + i
          )
      )
    )
  }, [calendarStartDate])

  const LIMIT = 100

  const { data, refetch } = useQuery(GET_BOOKINGS, {
    variables: {
      isInfiniteScroll: false,
      filters: {
        applicantId: null,
        recruiters: selectedRecruiters.map((recruiter) => recruiter.value),
        startDate: calendarStartDate,
        endDate: calendarEndDate,
      },
      pageInfo: { offset: 0, limit: LIMIT },
    },
  })

  const bookings = data?.getBookings?.items ?? []

  return (
    <div className="flex h-full w-full flex-col gap-3 overflow-hidden px-5 pb-5">
      <CalendarHeader
        calendarStartDate={calendarStartDate}
        calendarEndDate={calendarEndDate}
        setCalendarStartDate={setCalendarStartDate}
        setCalendarEndDate={setCalendarEndDate}
        selectedRecruiters={selectedRecruiters}
        setSelectedRecruiters={setSelectedRecruiters}
      />

      <CalendarFiltersRow
        selectedRecruiters={selectedRecruiters}
        setSelectedRecruiters={setSelectedRecruiters}
      />

      <div className="grid w-full grid-cols-7 gap-2">
        {days.map((day) => (
          <div
            className={cn(
              'flex flex-col p-1 text-center text-sm',
              dayjs(day).isSame(new Date(), 'day')
                ? 'font-semibold text-doubleNickel-success-600'
                : 'text-doubleNickel-gray-700'
            )}
            key={day.toString()}
          >
            {dayjs(day).format('M') !== dayjs(new Date()).format('M')
              ? dayjs(day).format('MMM')
              : dayjs(day).format('ddd')}
            <div className="text-lg">{dayjs(day).format('DD')}</div>
          </div>
        ))}
      </div>

      <div className="grid h-full grid-cols-7 gap-2 overflow-auto ">
        {days.map((day) => (
          <DayColumn key={day.toString()}>
            {bookings
              .filter((booking) => {
                const bookingDate = new Date(booking.bookingStartTime)
                return bookingDate.getDay() === day.getDay()
              })
              .map((booking) => (
                <CalendarEvent
                  key={booking.bookingId}
                  booking={booking}
                  refetch={refetch}
                />
              ))}
          </DayColumn>
        ))}
      </div>
    </div>
  )
}

export default CalendarPage
